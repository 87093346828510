export const transformUser = (user) => {
    const normalRequests = user.requests || [];
    const touristSquaredRequests = user.manualRequests || [];
    const manualFulfillmentRequests = normalRequests.filter((req) => req.isManualFulfillment);
    const nonManualFulfillmentRequests = normalRequests.filter((req) => !req.isManualFulfillment);
    const allRequests = [
        ...normalRequests,
        ...touristSquaredRequests,
    ];
    const selectedRequestIndex = Math.max(
    // Math.max prevents -1 being set as value
    0, allRequests.findIndex((req) => req.isSelected));
    return Object.assign(Object.assign({}, user), { requests: normalRequests, manualRequests: touristSquaredRequests, manualFulfillmentRequests: manualFulfillmentRequests, nonManualFulfillmentRequests: nonManualFulfillmentRequests, allRequests, names: `${user.firstName} ${user.lastName}`.trim(), allRequestsTriggeredInternally: Boolean(allRequests.length && allRequests.every((req) => !req.isManual && req.triggeredInternally)), selectedRequest: allRequests[selectedRequestIndex], lastRequestSelected: selectedRequestIndex === allRequests.length - 1 });
};
