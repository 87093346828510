import { defineMessages } from 'react-intl';
export const onboardingRequestsMessages = defineMessages({
    businessClientOnboarding: {
        id: 'OnboardingRequests.businessClientOnboarding',
        defaultMessage: 'Business Client eOnboarding',
    },
    corporateClientOnboarding: {
        id: 'OnboardingRequests.corporateClientOnboarding',
        defaultMessage: 'Corporate Client eOnboarding',
    },
    eOnboarding: {
        id: 'OnboardingRequests.corporateClientOnboarding',
        defaultMessage: 'eOnboarding',
    },
    startApplication: {
        id: 'OnboardingRequests.startApplication',
        defaultMessage: 'Start Process',
    },
    whatToUse: {
        id: 'OnboardingRequests.whatToUse',
        defaultMessage: 'Tell us what you would like to use',
    },
    zeroToFour: {
        id: 'OnboardingRequests.zeroToFour',
        defaultMessage: '0-4 trades',
    },
    plusFive: {
        id: 'OnboardingRequests.plusFive',
        defaultMessage: '5+ trades',
    },
    tradeInformation: {
        id: 'OnboardingRequests.tradeInformation',
        defaultMessage: 'Trade Information',
    },
    tradingProducts: {
        id: 'OnboardingRequests.tradingProducts',
        defaultMessage: 'FX Trading Product(s)',
    },
    whatIsLiveTrader: {
        id: 'OnboardingRequests.whatIsLiveTrader',
        defaultMessage: 'What is FX Live Trader?',
    },
    liveTrader: {
        id: 'OnboardingRequests.liveTrader',
        defaultMessage: 'FX Live Trader',
    },
    liveTraderInfo: {
        id: 'OnboardingRequests.liveTraderInfo',
        defaultMessage: 'FX Live Trader Info',
    },
    liveTraderWeb: {
        id: 'OnboardingRequests.liveTraderWeb',
        defaultMessage: 'FX Live Trader Web',
    },
    liveConfirm: {
        id: 'OnboardingRequests.liveConfirm',
        defaultMessage: 'Live Confirm',
    },
    liveConfirmInfo: {
        id: 'OnboardingRequests.liveConfirmInfo',
        defaultMessage: 'Live Confirm Info',
    },
    whatIsLiveConfirm: {
        id: 'OnboardingRequests.whatIsLiveConfirm',
        defaultMessage: 'What is Live Confirm?',
    },
    liveTraderAndLiveConfirm: {
        id: 'OnboardingRequests.liveTraderAndLiveConfirm',
        defaultMessage: 'FX Live Trader & Live Confirm (recommended)',
    },
    spot: {
        id: 'OnboardingRequests.spot',
        defaultMessage: 'FX Spot',
    },
    spotOnly: {
        id: 'OnboardingRequests.spotOnly',
        defaultMessage: 'FX Spot Only',
    },
    forwards: {
        id: 'OnboardingRequests.forwards',
        defaultMessage: 'FX Forwards',
    },
    alsoForwards: {
        id: 'OnboardingRequests.alsoForwards',
        defaultMessage: 'Also FX Forwards',
    },
    forwardExchange: {
        id: 'OnboardingRequests.forwardExchange',
        defaultMessage: 'Forward Exchange',
    },
    hedging: {
        id: 'OnboardingRequests.hedging',
        defaultMessage: 'Hedging',
    },
    nonHedging: {
        id: 'OnboardingRequests.nonHedging',
        defaultMessage: 'EMIR-Hedging / Non-Hedging',
    },
    tradingTurnover: {
        // business refers to this field as Fx Trading limits
        id: 'OnboardingRequests.tradingTurnover',
        defaultMessage: 'Maximum Daily FX Trading Turnover',
    },
    defaultSettlementInstructions: {
        id: 'OnboardingRequests.defaultSettlementInstructions',
        defaultMessage: 'Default Settlement Instructions',
    },
    tradingCurrency: {
        id: 'OnboardingRequests.tradingCurrency',
        defaultMessage: 'Trading Currency',
    },
    settlementAccount: {
        id: 'OnboardingRequests.settlementAccount',
        defaultMessage: 'Settlement Account',
    },
    addSettlementAccount: {
        id: 'OnboardingRequests.addSettlementAccount',
        defaultMessage: 'Add Settlement Account',
    },
    confirmationInstructions: {
        id: 'OnboardingRequests.confirmationInstructions',
        defaultMessage: 'Confirmation Instructions',
    },
    methodOfConfirmation: {
        id: 'OnboardingRequests.methodOfConfirmation',
        defaultMessage: 'Method of Confirmation',
    },
    fourEyeCheck: {
        id: 'OnboardingRequests.fourEyeCheck',
        defaultMessage: '4-eye-check',
    },
    enterDataBeforeNextStep: {
        id: 'OnboardingRequests.enterDataBeforeNextStep',
        defaultMessage: 'You need to enter data for all clients before you can move onto the next stage.',
    },
    speculationNotSupported: {
        id: 'OnboardingRequests.speculationNotSupported',
        defaultMessage: 'Currently we do not support Speculation!',
    },
    amoutShouldBeOver: {
        id: 'OnboardingRequests.amoutShouldBeOver',
        defaultMessage: 'Amount should be over {amount}',
    },
    invalidProduct: {
        id: 'OnboardingRequests.invalidProduct',
        defaultMessage: 'Invalid Product',
    },
    invalidCurrency: {
        id: 'OnboardingRequests.invalidCurrency',
        defaultMessage: 'Invalid Currency',
    },
    invalidMethod: {
        id: 'OnboardingRequests.invalidMethod',
        defaultMessage: 'Invalid Method',
    },
    requiredField: {
        id: 'OnboardingRequests.requiredField',
        defaultMessage: 'Required Field',
    },
    onboardingApplicationProgress: {
        id: 'OnboardingRequests.onboardingApplicationProgress',
        defaultMessage: 'Application {percentage}% complete',
    },
    envelopesSigningProgress: {
        id: 'OnboardingRequests.envelopesSigningProgress',
        defaultMessage: 'eSignature signing {percentage}% complete',
    },
    successfulRequestSubmission: {
        id: 'OnboardingRequests.successfulRequestSubmission',
        defaultMessage: 'Thanks for submitting your request.',
    },
    successfulRequestSubmissionInfo: {
        id: 'OnboardingRequests.successfulRequestSubmissionInfo',
        defaultMessage: 'You can follow the live status of your request below. An email will be sent once your setup is complete, at which point you will need to re-login to the portal in order to see your new entitlements.',
    },
    successfulLegalSubmissionInfo: {
        id: 'OnboardingRequests.successfulLegalSubmissionInfo',
        defaultMessage: 'The relevant legal documentation has been sent to the signatories below. Please follow up with them directly if necessary to ensure prompt setup of your selected product(s).',
    },
    successfulRequestCompletionInfo: {
        id: 'OnboardingRequests.successfulRequestCompletionInfo',
        defaultMessage: '<bolded>Automatic</bolded> setup has been completed for the following companies. An email will be sent to confirm how to access the new application(s). At this point you will need to re-login to the portal in order to see your new entitlements: {requests}',
    },
    successfulManualRequestCompletionInfo: {
        id: 'OnboardingRequests.successfulManualRequestCompletionInfo',
        defaultMessage: 'Unfortunately we have not been able to automatically match the following companies to your profile for a fully-digital onboarding. We will contact you for further clarification. {manualRequests}',
    },
    successfulManualFulfillmentRequestCompletionInfo: {
        id: 'OnboardingRequests.successfulManualFulfillmentRequestCompletionInfo',
        defaultMessage: "The following companies require <bolded>manual</bolded> configuration on our side. We'll be in touch with you in case we need further information. Once everything is setup, you will be informed via email: {manualFulfillmentRequests}",
    },
    salesContactsInfo: {
        id: 'OnboardingRequests.salesContactsInfo',
        defaultMessage: "Options for next steps are show below, alternatively if you wish to reach out to someone in person your advisor's contact details are shown below.",
    },
    successfulSignatoriesSigningCompletion: {
        id: 'OnboardingRequests.successfulSignatoriesSigningCompletion',
        defaultMessage: "We're now verifying your selected signatory(ies). Once verified we'll automatically issue the legal documents to be eSigned. You can follow the status by returning to this page at any time.",
    },
    successfulSignatoriesSigningCompletionExtraInfo: {
        id: 'OnboardingRequests.successfulSignatoriesSigningCompletionExtraInfo',
        defaultMessage: "If we need any further information or need to change any signatories we'll be in touch with you directly.",
    },
    whatWouldYouLikeToUseInfo: {
        id: 'OnboardingRequests.whatWouldYouLikeToUseInfo',
        defaultMessage: '{FXLiveTrader} is Commerzbank’s electronic Trading platform for Foreign Exchange Trading for Corporate Clients. FX is short for Foreign Exchange.{br}{br}{LiveConfirm} is our online Confirmation platform for your FX Exchange Trading, Foreign Exchange Options, Interest Rates Derivatives and Commodities&Equitites Business for Corporate Clients.',
    },
    fxTradingProductsInfo: {
        id: 'OnboardingRequests.fxTradingProductsInfo',
        defaultMessage: 'Choose {FxSpotOnly} if you need to do currency conversations with value dates of sameday or up to two trading days in the future.{br}{br}Choose {Forwards} if you need to do currency conversations with value dates of more than two trading days in the future (FX Forwards or Currency Swaps). This will require you to fill in more documents.{br} Learn more about {FxForwardsVideoGuidesLink}.',
    },
    fxTradingProductsInfoWhenOnlySpotIsAvailable: {
        id: 'OnboardingRequests.fxTradingProductsInfoWhenOnlySpotIsAvailable',
        defaultMessage: 'The ability to choose FX Forwards is coming soon, for now only FX Spot can be requested.',
    },
    fxTradingProductsVideoGuides: {
        id: 'OnboardingRequests.fxTradingProductsVideoGuides',
        defaultMessage: ' Learn more about {FxForwardsVideoGuidesLink}.',
    },
    forwardExchangeInfo: {
        id: 'OnboardingRequests.forwardExchangeInfo',
        defaultMessage: 'Choose {hedging} if the purpose of your Foreign Exchange transactions is hedging the risks of your underlying payment obligations in foreign currencies. In case you specifically need EMIR-Reports or do business which is not hedging the risks of your payments or speculative, choose {nonhedging}. In case all of this means nothing to you then choose Hedging.',
    },
    dailyFxTradingTurnoverInfo: {
        id: 'OnboardingRequests.dailyFxTradingTurnoverInfo',
        defaultMessage: "Please select your expected maximum turnover in EUR equivalent. This will help us to evaluate appropriate settlement limits.Higher limits may result in longer lead times. Please ensure you've selected the most suitable to your requirements.",
    },
    tradingCurrencyInfo: {
        id: 'OnboardingRequests.tradingCurrencyInfo',
        defaultMessage: 'Please choose your default where you want to settle your deals. You can choose a 10-digit Commerzbank account or an external IBAN number.',
    },
    methodOfConfirmationInfo: {
        id: 'OnboardingRequests.methodOfConfirmationInfo',
        defaultMessage: '{unibank} means that all your deals executed with Commerzbank will be routed into Live Confirm. {multibank} also includes your trades done with other Banks. Please choose Multibank only, if you already have a SWIFT-code with us.',
    },
    fourEyeCheckInfo: {
        id: 'OnboardingRequests.fourEyeCheckInfo',
        defaultMessage: 'Our Live Confirm offers the principle of a 4-eye-check. Trades confirmed under 4-eye are only marked as confirmed once two persons have confirmed them. If you go for this option we strongly suggest that you have at least three employees setup for this option to ensure holiday / sickleave coverage.',
    },
    addRequestFromLeftHandSideMenu: {
        id: 'OnboardingRequests.addRequestFromLeftHandSideMenu',
        defaultMessage: 'Please add your accounts in the eOnboarding Request section on the left.',
    },
    yourOnboardingRequestCompleted: {
        id: 'OnboardingRequests.yourOnboardingRequestCompleted',
        defaultMessage: 'Thanks for your submission',
    },
    startNewRequest: {
        id: 'OnboardingRequests.startNewRequest',
        defaultMessage: 'Start new Request',
    },
    startNewTouristOnboarding: {
        id: 'OnboardingRequests.startNewTouristOnboarding',
        defaultMessage: 'If you want to start again or amend your current submission, click on {startNewRequest}.',
    },
    startLiveTrader: {
        id: 'OnboardingRequests.startLiveTrader',
        defaultMessage: 'Start FX Live Trader',
    },
    startLiveConfirm: {
        id: 'OnboardingRequests.startLiveConfirm',
        defaultMessage: 'Start Live Confirm',
    },
    waitingForSignatures: {
        id: 'OnboardingRequests.waitingForSignatures',
        defaultMessage: 'Waiting for eSignatures...',
    },
    resetOnboarding: {
        id: 'OnboardingRequests.resetOnboarding',
        defaultMessage: 'Reset onboarding',
    },
    resetOnboardingApplicationInfo: {
        id: 'OnboardingRequests.resetOnboardingApplicationInfo',
        defaultMessage: 'This action will erase all of your current onboarding progress.',
    },
    resetOnboardingSuccessMessage: {
        id: 'OnboardingRequests.resetOnboardingSuccessMessage',
        defaultMessage: 'Onboarding successfully reset.',
    },
    areYouSureYouWantToResetOnboardingApplication: {
        id: 'OnboardingRequests.areYouSureYouWantToResetOnboardingApplication',
        defaultMessage: 'Are you sure you want to reset?',
    },
    settlementLimitSizeWarning: {
        id: 'OnboardingRequests.settlementLimitSizeWarning',
        defaultMessage: 'Due to the size of the limit this requires more time, are you sure you want to select this?',
    },
    iconsLegend: {
        id: 'OnboardingRequests.iconsLegend',
        defaultMessage: 'Signatories status',
    },
    iconSentInfo: {
        id: 'OnboardingRequests.iconSentInfo',
        defaultMessage: 'The recipient has been sent an email notification that it is their turn to sign document(s).',
    },
    iconDeliveredInfo: {
        id: 'OnboardingRequests.iconDeliveredInfo',
        defaultMessage: 'The recipient has viewed the documents but has not signed them yet.',
    },
    iconAutoRespondedInfo: {
        id: 'OnboardingRequests.iconAutoRespondedInfo',
        defaultMessage: "The recipient's email system auto-responded to the email from DocuSign. This status is used by the DocuSign webapp (also known as the DocuSign console) to inform senders about the auto-responded email.",
    },
    iconRejectedInfo: {
        id: 'OnboardingRequests.iconRejectedInfo',
        defaultMessage: 'The recipient declined to sign the documents in the envelope.',
    },
    iconSignedInfo: {
        id: 'OnboardingRequests.iconSignedInfo',
        defaultMessage: 'The recipient has completed their actions (signing or other required actions if not a signer) for an envelope.',
    },
    iconPendingLegitimisationInfo: {
        id: 'OnboardingRequests.iconPendingLegitimisationInfo',
        defaultMessage: 'The legitimization process for the recipient is in progress. The eSignature process will begin once complete.',
    },
    bookAppointment: {
        id: 'OnboardingRequests.bookAppointment',
        defaultMessage: 'Book an appointment',
    },
    methodOfConfirmationDisabledInfo: {
        id: 'OnboardingRequests.methodOfConfirmationDisabledInfo',
        defaultMessage: 'If Method of Confirmation field is locked then this has already been configured during a previous setup request.',
    },
    noSettlementAccounts: {
        id: 'OnboardingRequests.noSettlementAccounts',
        defaultMessage: 'You need at least two different currency accounts in order to do FX trading.\nPlease add the required currencies and we will initialise their creation.',
    },
    oneNonUniqueSettlementAccounts: {
        id: 'OnboardingRequests.oneNonUniqueSettlementAccounts',
        defaultMessage: 'You already have {accountsCount} {accountsCurrency} account(s) but in order to do FX trading you need at least one other currency. Please add the desired currency and we will initialise its creation.',
    },
    multipleNonUniqueSettlementAccounts: {
        id: 'OnboardingRequests.multipleNonUniqueSettlementAccountsx',
        defaultMessage: 'You already have {accountsCount} {accountsCurrency} account(s).',
    },
    addingnonUniqueSettlementAccountsWarning: {
        id: 'OnboardingRequests.addingnonUniqueSettlementAccountsWarning',
        defaultMessage: 'You already have {accountsCount} {accountsCurrency} account(s), are you sure you want to add another?',
    },
    automaticallyGenerated: {
        id: 'OnboardingRequests.automaticallyGenerated',
        defaultMessage: 'To be created',
    },
    noPreferredIncomingAndOutgoingAccountsWarning: {
        id: 'OnboardingRequests.noPreferredIncomingAndOutgoingAccountsWarning',
        defaultMessage: "We've noticed you have several {currencies} accounts. We recommend you set your preferred choice once you've logged into FX Live Trader. Please see the user guide for more info.",
    },
    successfulTouristUserViewInfo: {
        id: 'OnboardingRequests.successfulTouristUserViewInfo',
        defineMessages: "Thanks for providing your details, we'll be in touch with you shortly. Please keep in mind our business hours which are {businessHours} so please understand that any request outside these hours might be responded with a delay only.",
    },
    twoCurrenciesRequired: {
        id: 'OnboardingRequests.twoCurrenciesRequired',
        defineMessages: 'You must select at least two currencies',
    },
    companyDetails: {
        id: 'OnboardingRequests.companyDetails',
        defineMessages: 'Company details',
    },
    addNewCompany: {
        id: 'OnboardingRequests.addNewCompany',
        defineMessages: 'Add new company',
    },
    addNewCompanySuccess: {
        id: 'OnboardingRequests.addNewCompanySuccess',
        defineMessages: 'New company successfully added',
    },
    addManualCounterpartyIdExplanationText: {
        id: 'OnboardingRequests.addManualCounterpartyIdExplanationText',
        defaultMessage: 'If you know your 7 digit account number please provide it here. If you have a 10 digit one, please provide the last 7 digits. Otherwise leave it blank.',
    },
    derivativeTurnover: {
        id: 'OnboardingRequests.derivativeTurnover',
        defaultMessage: 'Maximum Maturity of your trades',
    },
    derivativeTurnoverInfo: {
        id: 'OnboardingRequests.derivativeTurnoverInfo',
        defaultMessage: 'In order to determine appropriate derivative limits you need to give us an indication on the expected maximum tenor of your trades.',
    },
    calculatingNextSteps: {
        id: 'OnboardingRequests.calculatingNextSteps',
        defaultMessage: 'Calculating next steps...',
    },
    manualRequestExplanation: {
        id: 'OnboardingRequests.manualRequestExplanation',
        defaultMessage: "Please note it won't be possible to onboard this account automatically. We will be in touch with you shortly in case of missing legal documents.",
    },
});
