import { createSelector } from '@reduxjs/toolkit';
import { authSelector, browserSelector } from 'store/reducers';
import { ENVELOPES_VALIDATION_PAGE_ACCESSIBLE_STATUSES, ONBOARDING_PAGE_ACCESSIBLE_STATUSES, OVERALL_STATUS, } from 'utils/mappers';
import { userSelector } from './userSelectors';
export const authenticatedRoutesSelector = createSelector([authSelector, userSelector, browserSelector, () => window.navigator.cookieEnabled], (auth, user, browser, cookieEnabled) => {
    const { sessionValid, loginServerFailure, userServerFailure } = auth;
    const { isSupported: browserIsSupported } = browser;
    const { id: userId, allRequestsTriggeredInternally: allRequestsTriggeredBySales, allowRegisterInterest, notSupported: userNotSupported, overallStatus: userOverallStatus, requests: normalRequests = [], manualRequests: touristSquaredRequests = [], allRequests = [], selectedEmail: userSelectedEmail, selectedTelephone: userSelectedTelephone, emailVerified, } = user;
    const defaultRules = Boolean(userId &&
        cookieEnabled &&
        sessionValid &&
        browserIsSupported &&
        ENVELOPES_VALIDATION_PAGE_ACCESSIBLE_STATUSES.includes(userOverallStatus) === false &&
        ONBOARDING_PAGE_ACCESSIBLE_STATUSES.includes(userOverallStatus) === false);
    const isTouristFlowComleted = userOverallStatus === OVERALL_STATUS.COMPLETED_TOURIST;
    return {
        LOGIN_PORTALS: Boolean(!sessionValid && !loginServerFailure),
        LANDING_PAGE: Boolean(defaultRules &&
            allowRegisterInterest &&
            isTouristFlowComleted === false &&
            allRequestsTriggeredBySales === false &&
            (userSelectedEmail ? emailVerified : true)),
        CONTACT_DETAILS: Boolean(defaultRules &&
            allowRegisterInterest &&
            isTouristFlowComleted === false &&
            allRequestsTriggeredBySales === false &&
            (userSelectedEmail ? emailVerified : true)),
        ACCOUNT_SELECTION: Boolean(defaultRules &&
            allowRegisterInterest &&
            isTouristFlowComleted === false &&
            allRequestsTriggeredBySales === false &&
            userSelectedEmail &&
            emailVerified),
        EMAIL_VERIFICATION: Boolean(defaultRules && userSelectedEmail && !emailVerified),
        ONBOARDING_REQUESTS: Boolean(defaultRules &&
            allowRegisterInterest &&
            isTouristFlowComleted === false &&
            userNotSupported === false &&
            allRequestsTriggeredBySales === false &&
            userSelectedEmail &&
            emailVerified &&
            allRequests.length),
        ONBOARDING_FORMS: Boolean(defaultRules &&
            allowRegisterInterest &&
            isTouristFlowComleted === false &&
            userNotSupported === false &&
            userSelectedEmail &&
            (allRequestsTriggeredBySales ? true : emailVerified) &&
            allRequests.length &&
            normalRequests.every((req) => req.triggeredInternally || req.isValidated) &&
            touristSquaredRequests.every((req) => req.isValidated)),
        ENVELOPES_VALIDATION: ENVELOPES_VALIDATION_PAGE_ACCESSIBLE_STATUSES.includes(userOverallStatus),
        ONBOARDING_STATUS: ONBOARDING_PAGE_ACCESSIBLE_STATUSES.includes(userOverallStatus),
        UNSUPPORTED_BROWSER: !browserIsSupported,
        UNSUPPORTED_USER: Boolean(defaultRules && userNotSupported && !allowRegisterInterest),
        TOURIST_USERS: Boolean(defaultRules &&
            isTouristFlowComleted === false &&
            userNotSupported &&
            allowRegisterInterest &&
            userSelectedEmail &&
            emailVerified &&
            userSelectedTelephone),
        TOURIST_COMPLETED: Boolean(defaultRules && allowRegisterInterest && isTouristFlowComleted),
        COOKIES_DISABLED: !cookieEnabled,
        LOGIN_FAILED: Boolean(!sessionValid && loginServerFailure),
        USER_FAILED: Boolean(userServerFailure),
    };
});
