import { LOCALES } from 'i18n/locales';
export const ERROR = 'error';
export const DEFAULT_CURRENCY = 'EUR';
export const DEFAULT_COUNTRY_CODE = 'DE';
export const DEFAULT_LEGAL_DOCS_LANGUAGE = LOCALES.GERMAN;
export const DEFAULT_SESSION_DURATION = 3600000;
export const DEFAULT_ERROR_MESSAGE = 'Sorry! Something went wrong on our side!';
export const MINIMUM_SESSION_DURATION = 300000;
export const MIN_PHONE_LENGTH = 5;
export const MANUAL_COUNTERPARTY_ACCOUNT_NUMBER_LENGTH = 7;
export const KEYBOARD_BUTTONS = {
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    TAB: 'Tab',
};
export const MINIMUM_REQUIRED_UNIQUE_SETTLEMENT_ACCOUNTS = 2;
export const DEFAULT_CURRENCIES_GROUPS = {
    popular: { name: { en: '', de: '' }, currencies: [] },
    other: { name: { en: '', de: '' }, currencies: [] },
};
