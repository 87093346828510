var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect, useState } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { Layer, ReusableSvg } from 'components';
import { StyledClearButton, StyledEndIcon, StyledInput, StyledInputContainer, StyledInputIconWrapper, StyledLabel, StyledLabelWrapper, } from 'components/Input/Input.styles';
import { KEYBOARD_BUTTONS } from 'constants/common';
import { useFocus } from 'hooks';
import { StyledError } from 'shared/styled/Layout';
// eslint-disable-next-line react/display-name
export const FormikInput = forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { label, id, endIcon, clearIcon = React.createElement(CloseIcon, { width: "75%", height: "75%" }), onClear, children, className = '', hasGreenBackground, name, type = 'text', disabled = false, readOnly = false, lang = navigator.language, tooltipText, tooltipTextAlign = 'center', tooltipHtml = false, autoComplete = 'disabled', numbersOnlyField = false, fadedPlaceholder = false, showError = true, fullWidth = false, width = 'auto', hidden, mask, onBlur, onKeyDown, onChange, replacePattern } = props, rest = __rest(props, ["label", "id", "endIcon", "clearIcon", "onClear", "children", "className", "hasGreenBackground", "name", "type", "disabled", "readOnly", "lang", "tooltipText", "tooltipTextAlign", "tooltipHtml", "autoComplete", "numbersOnlyField", "fadedPlaceholder", "showError", "fullWidth", "width", "hidden", "mask", "onBlur", "onKeyDown", "onChange", "replacePattern"]);
    const [_a, meta, { setValue, setTouched }] = useField({
        name,
    }), { value } = _a, field = __rest(_a, ["value"]);
    const { formatMessage } = useIntl();
    const { ref: inputRef, focused } = useFocus(); // @TODO review if needed
    const formatInput = (val) => {
        let result = '';
        if (!val) {
            return result;
        }
        if (type === 'number') {
            let num;
            try {
                num = typeof val === 'bigint' ? val : BigInt(val);
            }
            catch (err) {
                num = Number(val);
            }
            result = new Intl.NumberFormat(lang, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(num);
        }
        else {
            result = String(val);
        }
        return mask ? mask(result) : result;
    };
    useEffect(() => {
        if (!focused) {
            setDisplayValue(formatInput(value));
        }
    }, [lang, value, focused]);
    const [displayValue, setDisplayValue] = useState(() => formatInput(value));
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const toggleTooltipOpenState = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };
    const handleChange = (ev) => {
        var _a;
        if (disabled || readOnly) {
            return;
        }
        let newValue;
        newValue = numbersOnlyField
            ? (ev.target.value || '').replace(/[^0-9]/g, '')
            : mask
                ? mask(ev.target.value)
                : ev.target.value;
        if (type === 'number' && ev.target.value) {
            newValue =
                Number.MAX_SAFE_INTEGER < Number(ev.target.value)
                    ? BigInt(ev.target.value)
                    : Number(parseInt(ev.target.value));
        }
        if (replacePattern) {
            newValue = (_a = String(newValue)) === null || _a === void 0 ? void 0 : _a.replace(replacePattern, '');
        }
        setTouched(true, false);
        setDisplayValue(String(newValue));
        setValue(newValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
    };
    const handleFocus = () => setDisplayValue(typeof value === 'bigint' ? value.toString() : (value !== null && value !== void 0 ? value : ''));
    const handleBlur = (event) => {
        setDisplayValue(formatInput(value));
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
    };
    const handleKeyDown = (event) => {
        var _a;
        if (event.key === KEYBOARD_BUTTONS.ENTER) {
            setTouched(true, false);
            const currentRef = ref || inputRef;
            if ('current' in currentRef) {
                (_a = currentRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            }
            event.preventDefault();
        }
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
    };
    return (React.createElement(StyledInputContainer, { hasLabel: !!label, hasError: showError && !!meta.error && !disabled && meta.touched, className: className, hasGreenBackground: hasGreenBackground, "data-testid": "input", disabled: disabled, readOnly: readOnly, isHidden: hidden, width: width, fullWidth: fullWidth },
        React.createElement(StyledInput, Object.assign({ ref: ref || inputRef }, rest, { id: id, type: focused ? type : 'text', autoComplete: autoComplete, hasIcon: !!endIcon, value: displayValue, onKeyDown: handleKeyDown, onFocus: handleFocus, onBlur: handleBlur, disabled: disabled, onChange: handleChange, hasGreenBackground: hasGreenBackground, fadedPlaceholder: fadedPlaceholder })),
        React.createElement("input", Object.assign({ type: "hidden", tabIndex: -1 }, field)),
        label && (React.createElement(StyledLabelWrapper, { active: true, hasTooltip: !!tooltipText },
            React.createElement(StyledLabel, { htmlFor: id, active: !!value, disabled: disabled, hasTooltip: !!tooltipText, hasGreenBackground: hasGreenBackground }, label),
            tooltipText && (React.createElement(StyledInputIconWrapper, null,
                React.createElement(ReusableSvg, { onClick: toggleTooltipOpenState, Icon: InfoIcon, width: "14px", height: "14px", hoverable: true }),
                React.createElement(Layer, { name: label, closeLinkLabel: label, open: isTooltipOpen, onCloseClick: toggleTooltipOpenState }, tooltipText))))),
        endIcon && React.createElement(StyledEndIcon, null, endIcon),
        !!onClear && React.createElement(StyledClearButton, { onClick: onClear }, clearIcon),
        children,
        showError && meta.error && (React.createElement(StyledError, null, (() => {
            var _a;
            try {
                return typeof meta.error === 'string'
                    ? meta.error
                    : 'translation' in meta.error
                        ? // @ts-ignore
                            formatMessage(meta.error.translation, meta.error.params)
                        : // @ts-ignore
                            formatMessage(meta.error, Object.assign({}, (((_a = meta.error) === null || _a === void 0 ? void 0 : _a.values) || {})));
            }
            catch (err) {
                console.log(err);
                return '';
            }
        })()))));
});
export default FormikInput;
