import React, { useState } from 'react';
import { people___profile as ProfileIcon } from '@lsg/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useSaveUserLanguageMutation } from 'api';
import { ReactComponent as GermanIcon } from 'assets/icons/flags/de.svg';
import { ReactComponent as GreatBritainIcon } from 'assets/icons/flags/gb.svg';
import CommerzbankIcon from 'assets/icons/logo.png';
import { Modal, ReusableSvg, Toggle } from 'components';
import { ROUTES } from 'constants/routes';
import { LOCALES } from 'i18n/locales';
import { globalMessages, onboardingRequestsMessages } from 'i18n/messages';
import { FlexContainer } from 'shared/styled';
import { userSelector, authenticatedRoutesSelector, configSelector, authSelector } from 'store';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
import { AUTH_CHANNEL_MAPPER, AUTH_CHANNEL_MAPPER_REVERSED } from 'utils/mappers';
import { MyProfile } from 'views';
import { ApplicationHeader, Container, Logo, Pipe, SmallText, Text } from './Header.styles';
export const Header = React.memo(function Header() {
    var _a;
    const theme = useTheme();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const user = useSelector(userSelector);
    const canActivate = useSelector(authenticatedRoutesSelector);
    const { sessionValid } = useSelector(authSelector);
    const { AUTH_CHANNEL } = useSelector(configSelector);
    const [saveUserLanguage] = useSaveUserLanguageMutation();
    const [openedModal, setOpenedModal] = useState(false);
    const openProfileModal = () => {
        setOpenedModal(true);
    };
    const closeProfileModal = () => setOpenedModal(false);
    const toggleLang = (event) => {
        const newLang = event.target.checked ? LOCALES.GERMAN : LOCALES.ENGLISH;
        saveUserLanguage({ id: user === null || user === void 0 ? void 0 : user.id, language: newLang });
        trackDataLayerEvent({
            event: 'basicInfo',
            country: 'Germany',
            language: newLang,
            pageId: location.pathname,
            pageURL: location.pathname,
            pageType: 'Antragsstrecke',
            pageTemplate: null,
            pageTitle: pathnameToPageTitle(location.pathname),
            pageLayout: 'desktop',
            clusterName: null,
            eventContext: 'Digital Product Portal',
            pageReleaseDate: null,
            contentUpdateDate: null,
            corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
        });
    };
    return (React.createElement(ApplicationHeader, null,
        React.createElement(Container, null,
            React.createElement(Logo, { onClick: () => {
                    if (canActivate.LANDING_PAGE) {
                        navigate(ROUTES.LANDING_PAGE);
                        trackDataLayerEvent({
                            event: 'mainNavigationClick',
                            contentUpdateDate: null,
                            corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
                            eventContext: 'Digital Product Portal',
                            language: user.language,
                            navigationLabel: 'logoIcon',
                            navigationElement: 'navigation_level_1',
                            pageId: window.location.pathname,
                            pageLayout: 'desktop',
                            pageReleaseDate: null,
                            pageTemplate: null,
                            pageTitle: pathnameToPageTitle(window.location.pathname),
                            pageType: 'Antragsstrecke',
                            pageURL: window.location.href,
                            targetId: null,
                            targetURL: window.location.origin,
                        });
                    }
                }, src: CommerzbankIcon, alt: "Commerzbank AG" }),
            React.createElement(Text, null, AUTH_CHANNEL === AUTH_CHANNEL_MAPPER.PUK
                ? formatMessage(onboardingRequestsMessages.businessClientOnboarding)
                : AUTH_CHANNEL === AUTH_CHANNEL_MAPPER.FK
                    ? formatMessage(onboardingRequestsMessages.corporateClientOnboarding)
                    : formatMessage(onboardingRequestsMessages.eOnboarding))),
        React.createElement(Container, null,
            React.createElement(Toggle, { name: "language-toggle", leftLabel: React.createElement(FlexContainer, null,
                    React.createElement(ReusableSvg, { width: '24px', height: '30px', Icon: GreatBritainIcon, marginRight: theme.spacing(1) }),
                    React.createElement(SmallText, null, formatMessage(globalMessages.English))), rightLabel: React.createElement(FlexContainer, null,
                    React.createElement(SmallText, null, formatMessage(globalMessages.German)),
                    React.createElement(ReusableSvg, { width: '24px', height: '30px', Icon: GermanIcon, marginLeft: theme.spacing(1) })), textColor: theme.colors.white, disabled: false, checked: (_a = user === null || user === void 0 ? void 0 : user.language) === null || _a === void 0 ? void 0 : _a.startsWith(LOCALES.GERMAN), onChange: toggleLang }),
            React.createElement(SmallText, null, user.names),
            React.createElement(Pipe, null, "|"),
            (user === null || user === void 0 ? void 0 : user.id) && sessionValid && (React.createElement(ReusableSvg, { icon: ProfileIcon, cursor: "pointer", color: "white", lsgIcon: true, onClick: openProfileModal }))),
        React.createElement(Modal, { name: 'My Profile Modal', language: user.language, isOpen: openedModal, closeModal: closeProfileModal, variant: "small", scrollableContent: false, displayDefaultActionButtons: false, dismissOnSubmit: false, padding: '1.5% 3%' },
            React.createElement(MyProfile, { closeProfileModal: closeProfileModal }))));
});
export default Header;
