import React, { useEffect, useRef } from 'react';
import range from 'lodash.range';
import { useSelector } from 'react-redux';
import { LAYER_ELEMENT_CLASSNAME, MODAL_ELEMENT_CLASSNAME } from 'constants/htmlElements';
import { userSelector, configSelector } from 'store';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
import { AUTH_CHANNEL_MAPPER, AUTH_CHANNEL_MAPPER_REVERSED } from 'utils/mappers';
export const GoogleAnalyticsProvider = ({ children }) => {
    const { AUTH_CHANNEL, GOOGLE_ANALYTICS_BASE_URL_FK, GOOGLE_ANALYTICS_BASE_URL_PK, ENABLE_GOOGLE_ANALYTICS, } = useSelector(configSelector);
    const { id: userId, channel: userChannel, language } = useSelector(userSelector);
    const scrollRef = useRef({
        scrollRanges: [range(0, 1), range(1, 26), range(26, 51), range(51, 76), range(76, 101)],
        currentScroll: null,
    });
    useEffect(() => {
        trackDataLayerEvent({
            event: 'pageView',
            clusterName: null,
            contentUpdateDate: null,
            eventContext: 'Digital Product Portal',
            language: language,
            pageId: location.pathname,
            pageLayout: 'desktop',
            pageReleaseDate: null,
            pageTemplate: null,
            pageTitle: pathnameToPageTitle(location.pathname),
            pageType: 'Antragsstrecke',
            pageURL: window.location.href,
            corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
        });
    }, [location.pathname]);
    useEffect(() => {
        const trackScroll = (event) => {
            var _a, _b, _c;
            const target = event.target;
            const scrollTop = target.scrollTop || ((_a = document.scrollingElement) === null || _a === void 0 ? void 0 : _a.scrollTop) || 0;
            const scrollHeight = target.scrollHeight || ((_b = document.scrollingElement) === null || _b === void 0 ? void 0 : _b.scrollHeight) || 0;
            const clientHeight = target.clientHeight || ((_c = document.scrollingElement) === null || _c === void 0 ? void 0 : _c.clientHeight) || 0;
            const scrollDepth = scrollTop / (scrollHeight - clientHeight);
            const scrollPercent = Math.min(Math.round(scrollDepth * 100), 100);
            const currentScrollRange = scrollRef.current.scrollRanges.find((range) => range.includes(scrollPercent));
            const currentScroll = currentScrollRange === null || currentScrollRange === void 0 ? void 0 : currentScrollRange[(currentScrollRange === null || currentScrollRange === void 0 ? void 0 : currentScrollRange.length) - 1];
            if (!currentScroll || currentScroll === scrollRef.current.currentScroll) {
                return;
            }
            scrollRef.current.currentScroll = currentScroll;
            trackDataLayerEvent({
                event: 'scrollPercentage',
                eventContext: 'Digital Product Portal',
                corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
                pageId: location.pathname,
                pageLayout: 'desktop',
                pageTemplate: null,
                pageTitle: pathnameToPageTitle(location.pathname),
                pageType: 'Antragsstrecke',
                pageURL: window.location.href,
                scrollDepth: currentScroll,
            });
        };
        const trackButtonClick = (event) => {
            if (event.target && 'localName' in event.target) {
                if (event.target.localName === 'button') {
                    const { innerText } = event.target || {};
                    trackDataLayerEvent({
                        event: 'buttonClick',
                        buttonLabel: innerText,
                        eventContext: 'Digital Product Portal',
                        corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
                        language: language,
                        pageTitle: pathnameToPageTitle(location.pathname),
                        pageURL: window.location.href,
                        pageLayout: 'desktop',
                        pageTemplate: null,
                        pageType: 'Antragsstrecke',
                        clusterName: null,
                        contentUpdateDate: null,
                        pageId: null,
                        pageReleaseDate: null,
                        processName: 'FX Corporate Onboarding',
                        stepName: location.pathname,
                    });
                }
            }
        };
        const trackFormSubmit = (event) => {
            if (event.target && 'localName' in event.target) {
                if (event.target.localName === 'form') {
                }
            }
        };
        const observerConfig = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.addedNodes.length) {
                    mutation.addedNodes.forEach((node) => {
                        var _a, _b, _c;
                        if (node instanceof HTMLElement) {
                            const sideLayerEl = node.classList.contains(LAYER_ELEMENT_CLASSNAME)
                                ? node
                                : ((_a = node.getElementsByClassName(LAYER_ELEMENT_CLASSNAME)) === null || _a === void 0 ? void 0 : _a.length)
                                    ? node.getElementsByClassName(LAYER_ELEMENT_CLASSNAME)[0]
                                    : null;
                            // @ts-ignore
                            const sideLayerElementName = (_c = (_b = sideLayerEl === null || sideLayerEl === void 0 ? void 0 : sideLayerEl.firstChild) === null || _b === void 0 ? void 0 : _b.dataset) === null || _c === void 0 ? void 0 : _c.name;
                            const modalElementName = node.hasAttribute('data-name') && node.classList.contains(MODAL_ELEMENT_CLASSNAME)
                                ? node.getAttribute('data-name')
                                : null;
                            if (sideLayerElementName) {
                                trackDataLayerEvent({
                                    event: 'sidelayerOpen',
                                    sidelayerName: sideLayerElementName,
                                    contentUpdateDate: null,
                                    corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
                                    eventContext: 'Digital Product Portal',
                                    pageId: location.pathname,
                                    pageLayout: 'desktop',
                                    pageReleaseDate: null,
                                    pageTemplate: null,
                                    pageTitle: pathnameToPageTitle(location.pathname),
                                    pageType: 'Antragsstrecke',
                                    pageURL: location.pathname,
                                });
                            }
                            if (modalElementName) {
                                trackDataLayerEvent({
                                    event: 'dialogOpen',
                                    dialogName: modalElementName,
                                    country: 'Germany',
                                    language: language,
                                    pageId: location.pathname,
                                    pageURL: location.pathname,
                                    pageType: 'Antragsstrecke',
                                    pageTemplate: null,
                                    pageTitle: pathnameToPageTitle(location.pathname),
                                    pageLayout: 'desktop',
                                    clusterName: null,
                                    eventContext: 'Digital Product Portal',
                                    pageReleaseDate: null,
                                    contentUpdateDate: null,
                                    corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
                                });
                            }
                        }
                    });
                }
            }
        });
        if (ENABLE_GOOGLE_ANALYTICS) {
            observer.observe(document, observerConfig);
            document.addEventListener('scroll', trackScroll, true);
            document.addEventListener('click', trackButtonClick, true);
            document.addEventListener('submit', trackFormSubmit, true);
        }
        return () => {
            observer.disconnect();
            document.removeEventListener('scroll', trackScroll, true);
            document.removeEventListener('click', trackButtonClick, true);
            document.removeEventListener('submit', trackFormSubmit, true);
        };
    }, []);
    useEffect(() => {
        if (!userId) {
            return;
        }
        const isFkClient = userChannel === AUTH_CHANNEL_MAPPER.FK;
        const baseUrl = isFkClient ? GOOGLE_ANALYTICS_BASE_URL_FK : GOOGLE_ANALYTICS_BASE_URL_PK;
        const gaConsentScript = document.createElement('script');
        gaConsentScript.id = 'cookiebox';
        gaConsentScript.type = 'text/javascript';
        gaConsentScript.src = isFkClient ? `${baseUrl}cmp_fk.js` : `${baseUrl}cmp_puk.js`;
        gaConsentScript.dataset.savePath = `${baseUrl}save`;
        const gaIntegrationScript = document.createElement('script');
        gaIntegrationScript.type = 'text/javascript';
        gaIntegrationScript.src = isFkClient
            ? `${baseUrl}gtm_integration_fk.js`
            : `${baseUrl}gtm_integration_puk.js`;
        try {
            if (ENABLE_GOOGLE_ANALYTICS) {
                document.head.appendChild(gaConsentScript);
                document.head.appendChild(gaIntegrationScript);
            }
        }
        catch (err) {
            console.error(`Failed to load GA scripts! Error: ${err}`);
        }
    }, [userId]);
    return React.createElement(React.Fragment, null, children);
};
export default GoogleAnalyticsProvider;
