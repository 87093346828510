import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetLegalLanguagesQuery, useGetCurrenciesGroupsQuery, useGetExternalUrlsConfigQuery, useGetSettlementLimitBandConfigQuery, useGetDerivativeLimitBandConfigQuery, } from 'api';
import { NO_INTENET_CONNECTION_TOAST_ID } from 'constants/index';
import { notificationAlertHandler } from 'utils/notificationHandlers';
export const ConfigProvider = ({ children }) => {
    useGetLegalLanguagesQuery();
    useGetCurrenciesGroupsQuery();
    useGetExternalUrlsConfigQuery();
    useGetSettlementLimitBandConfigQuery();
    useGetDerivativeLimitBandConfigQuery();
    useEffect(() => {
        const offlineHandler = () => {
            // @TODO move to browserMiddleware
            notificationAlertHandler(React.createElement(FormattedMessage, { id: 'Global.noInternetConnection' }), NO_INTENET_CONNECTION_TOAST_ID);
        };
        window.addEventListener('offline', offlineHandler);
        return () => {
            window.removeEventListener('offline', offlineHandler);
        };
    }, []);
    return React.createElement(React.Fragment, null, children);
};
export default ConfigProvider;
