import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { IS_LOCALHOST } from 'constants/environments';
const initialState = {
    sessionValid: true,
    loginServerFailure: false,
    userServerFailure: false,
    authToken: undefined,
    decodedAuthTokenNameId: undefined,
    mockedLoginCookieToken: '',
    useMockLogin: IS_LOCALHOST && process.env.USE_MOCK_LOGIN === 'true',
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        invalidateSession: (state) => {
            state.sessionValid = false;
        },
        loginServerFailure: (state) => {
            state.loginServerFailure = true;
        },
        userServerFailure: (state) => {
            state.userServerFailure = true;
        },
        setAuthTokenData: (state, action) => {
            var _a;
            state.authToken = action.payload.token;
            state.decodedAuthTokenNameId =
                ((_a = jwtDecode(action.payload.token || '')) === null || _a === void 0 ? void 0 : _a.nameid) || '';
        },
        setMockedLoginCookieToken: (state, action) => {
            state.mockedLoginCookieToken = action.payload.token;
        },
    },
});
export const { invalidateSession, loginServerFailure, userServerFailure, setAuthTokenData, setMockedLoginCookieToken, } = authSlice.actions;
export const authSelector = ({ auth }) => auth;
export default authSlice.reducer;
